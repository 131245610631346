import React from 'react'
import PropTypes from 'prop-types'
import ContentfulArticle from './blocks/article'
import ContentfulCaseStudy from './blocks/case-study'
import ContentfulClient from './blocks/client'
import ContentfulStats from './blocks/stat'
import ContentfulService from './blocks/service'
import ContentfulTeamMember from './blocks/team-member'
import ContentfulTechnology from './blocks/technology'
import ContentfulInstagramBlockFeed from './blocks/insta-feed'
import ContentfulJobs from './blocks/jobs'
import ContentfulTestimonial from './blocks/testimonial'
import ContentfulGallery from './blocks/galleryImage'

const blockMappings = {
  ContentfulArticle,
  ContentfulCaseStudy,
  ContentfulClient,
  ContentfulStats,
  ContentfulService,
  ContentfulTeamMember,
  ContentfulTechnology,
  ContentfulJobs,
  ContentfulTestimonial,
  ContentfulGallery,
  ContentfulInstagramBlockFeed,
}

const DbBlock = ({ block, section }) => {
  const Block = blockMappings[block.__typename]

  if (!Block) {
    console.log(block.__typename + ' has no block component to support it.')
    return null
  }

  block.__sectionTemplate = section.template
  return <Block data={block} key={block.id} />
}

DbBlock.propTypes = {
  block: PropTypes.object.isRequired,
  section: PropTypes.object.isRequired,
}

export default DbBlock
