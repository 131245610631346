import React from 'react'
import { Card, CardBody, CardTitle, CardText } from 'reactstrap'
import styled from 'styled-components'
import styles from './team.module.scss'

export default ({ data }) => {
  return (
    <Card className={`a-hover ${styles.team_member}`}>
      <div className={`${styles.image}`}>
        {data.photo && data.photo.file.url ? <Svg background={data.photo.file.url} className="profile-pic" /> : ''}
      </div>
      <CardBody>
        <div className="row">
          <div className="col-12 col-md-8 order-1">
            <CardTitle tag="h2" className={`h4 ${styles.name}`}>
              {data.name}
            </CardTitle>
            <p className={styles.postition}>{data.position}</p>
            <CardText tag="div" className="dyn-content">
              {data.bio && data.bio.childMarkdownRemark.html && (
                <div dangerouslySetInnerHTML={{ __html: data.bio.childMarkdownRemark.html }} />
              )}
            </CardText>
            {data.email && (
              <p className={styles.contact}>
                <span>E.</span> {data.email}
              </p>
            )}
            {data.phone && (
              <p className={styles.contact}>
                <span>T.</span> {data.phone}
              </p>
            )}
          </div>
        </div>
      </CardBody>
    </Card>
  )
}

const Svg = styled.div`
  margin: 0 auto;
  background-image: linear-gradient(to right, #07c5d1, #f134a8);
  -webkit-mask: url(${props => props.background}) no-repeat;
  mask: url(${props => props.background}) no-repeat;
  -webkit-mask-position: bottom center;
  height: 185px;
`
