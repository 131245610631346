import React from 'react'
import PropTypes from 'prop-types'
import DbBlock from './db-block'
import ContentfulContentTypeFeed from './blocks/content-type-feed'
import { Col } from 'reactstrap'
import AOS from 'aos'

const DbBlocks = ({ children, col, section, animation, duration }) => {
  if (typeof window !== `undefined`) {
    AOS.init({disable: 'mobile'})
  }

  return (children || []).map((block, i) => {
    if (block.__typename === 'ContentfulContentTypeFeed') {
      return (
        <ContentfulContentTypeFeed
          data={block}
          key={block.id}
          col={col}
          animation={animation}
          duration={duration}
        />
      )
    } else if ( block.__typename === 'ContentfulInstagramBlockFeed') {
      return ( <DbBlock block={block} section={section} /> )
    } else {
      return (
        <Col {...col} key={block.id} data-aos={animation} data-aos-duration={duration}>
          <DbBlock block={block} section={section} />
        </Col>
      )
    }
  })
}

const TypeNumOrString = PropTypes.oneOfType([PropTypes.number, PropTypes.string])

DbBlocks.propTypes = {
  children: PropTypes.array,
  col: PropTypes.shape({
    xs: TypeNumOrString,
    sm: TypeNumOrString,
    md: TypeNumOrString,
    lg: TypeNumOrString,
    xl: TypeNumOrString,
  }).isRequired,
  section: PropTypes.object.isRequired,
}

export default DbBlocks
