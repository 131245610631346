import React from 'react'
import Helmet from 'react-helmet'
import favicon from '../../images/favicon.png'

class DbHelmet extends React.Component {
  constructor({ data }) {
    super()
    this.data = data
  }

  render() {
    return (
      <Helmet>
        <title>{this.data.title ? this.data.title : 'Websites and web applications that perform | DoubleBrace'}</title>
        {this.data.description ? <meta name="description" content={this.data.description} /> : ''}
        <link rel="shortcut icon" href={favicon} />
        <script>{`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
    new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
    'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
    })(window,document,'script','dataLayer','GTM-KW7P88L');`}</script>
        <meta name="google-site-verification" content="iw6XAInk_hQXW6jSVJWqcDLc-Fb4Y0sq9OuMLyOWAoQ" />
      </Helmet>
    )
  }
}

export default DbHelmet
