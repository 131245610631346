import React, { Component } from 'react'
import TransitionLink, { TransitionPortal } from 'gatsby-plugin-transition-link'
import { TimelineMax, Power1 } from 'gsap'
import Logo from '../images/db-website-logo.png'

export default class Cover extends Component {
  constructor(props) {
    super(props)

    this.horizontal = this.horizontal.bind(this)
    this.cover = React.createRef()
    this.coverLogo = React.createRef()
  }


  horizontal = ({ props: { length: seconds } }) => {
    const wait = seconds / 6
    const half = (seconds - wait) / 2

    return new TimelineMax()
      .set(this.cover, { zIndex: 1 })
      .set(this.coverLogo, { display: 'block' })
      .to(this.cover, half, {
        opacity: 1,
        ease: Power1.easeInOut,
      })
      .to(
        this.cover,
        half,
        {
          opacity: 0,
          ease: Power1.easeInOut,
        },
        `+=${wait}`
      )
  }

  moveInDirection = ({ props, direction, node }) => {
    return this.horizontal({ props, direction, node })
  }

  render() {
    const direction = this.props.direction || 'left'
    const length = this.props.duration || 1
    const { exit: removedExit, entry: removedEntry, cover: removedProp, ...props } = this.props
    return (
      <>
        <TransitionLink
          exit={{
            length: length,
            trigger: ({ exit, node }) => this.moveInDirection({ props: exit, node, direction }),
          }}
          entry={{
            delay: length / 2,
          }}
          {...props}
        >
          {this.props.children}
        </TransitionLink>

        <TransitionPortal>
          <div
            ref={n => (this.cover = n)}
            style={{
              position: 'fixed',
              background: '#ffffff',
              top: 0,
              left: 0,
              width: '100vw',
              height: '100vh',
              zIndex: -1,
              opacity: 0,
            }}
          >
            <div ref={n => (this.coverLogo = n)} className="transitionLogo">
              <img src={Logo} alt="Double Brace Logo" />
            </div>
          </div>
        </TransitionPortal>
      </>
    )
  }
}
