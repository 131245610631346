import React from 'react'
import DbBlocks from '../db-blocks'
import { Row, Col } from 'reactstrap'

export default props => {
  const { colModel = { xs: 6 }, children, title, body, ...sectionProps } = props

  return (
    <>
      <Row>
        <Col lg="6" xl="7" data-aos="fade-up" data-aos-duration="450">
          <h2 className="sub-title">{title}</h2>
          {body && body.childMarkdownRemark.html ? (
            <div className="dyn-content"
              dangerouslySetInnerHTML={{
                __html: body.childMarkdownRemark.html,
              }}
            />
          ) : null}
        </Col>
        <Col lg="6" xl="5" data-aos="fade-up" data-aos-duration="450" className="sidebar-col">
          <div className="sidebar-col-inner">
            <Row className="no-gutters">
              <DbBlocks col={colModel} section={sectionProps}>
                {children}
              </DbBlocks>
            </Row>
          </div>
        </Col>
      </Row>
    </>
  )
}
