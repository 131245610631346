import React from 'react'
import { Card, CardBody, CardTitle, CardText } from 'reactstrap'
import styled from 'styled-components'
// import FadeLink from '../../../components/pageTransition'
import DBButton from '../../db-button'
import { Tween } from 'react-gsap'
const slugify = require(`slugify`)

// const slugify = require(`slugify`)

export default ({ data, job }) => {
  var path = '/jobs/' + slugify(data.pageTitle, { lower: true }) + '/'
  return (
	  <Tween css={{ top: '-20%', opacity: 0 }} delay={2.5} duration={1}>
			  <Card className="a-hover">
				  <CardBody>
					  <div className="row">
						  {data.icon ? (
							  <div className="col-12 col-md-4 image-holder order-1 order-md-2">
								  <Svg background={data.icon.file.url} width={data.icon.file.details.image.width} height={data.icon.file.details.image.height} className="landscape-card-svg mx-auto" />
								</div>
							) : (
							  ''
						  )}
						  <div className={data.icon ? 'col-12 col-md-8 align-self-center order-2 order-md-1' : 'col-12 col-md-8 align-self-center'}>
							  <CardTitle tag="p" className="h4">
								  {data.pageTitle}
							  </CardTitle>
							  <CardText tag="div" className="dyn-content">
								  {data.snippet ? (
									  <div
										  dangerouslySetInnerHTML={{ __html: data.snippet.childMarkdownRemark.html }}
										  className="d-none d-md-block"
									  />
								  ) : (
										  ''
                )}
                <DBButton label="Find out More" link={path} />
              </CardText>
						  </div>
					  </div>
				  </CardBody>
			  </Card>
	  </Tween>
  )
}


const Svg = styled.div`
  margin: 0 auto;
  width: 150%;
  position: absolute;
  top: -25px;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;
  background: linear-gradient(to right, #07c5d1, #f134a8);
  background-size: 150% 150%;
  -webkit-animation: AnimationName 10s ease infinite;
  -moz-animation: AnimationName 10s ease infinite;
  animation: AnimationName 10s ease infinite;

  @-webkit-keyframes AnimationName {
      0%{background-position:0% 50%}
      50%{background-position:100% 51%}
      100%{background-position:0% 50%}
  }
  @-moz-keyframes AnimationName {
      0%{background-position:0% 50%}
      50%{background-position:100% 51%}
      100%{background-position:0% 50%}
  }
  @keyframes AnimationName {
      0%{background-position:0% 50%}
      50%{background-position:100% 51%}
      100%{background-position:0% 50%}
  }

  -webkit-mask: url(${props => props.background}) no-repeat;
  mask: url(${props => props.background}) no-repeat;
  -webkit-mask-position: top;
`
