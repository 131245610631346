import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import { Col } from 'reactstrap'
import DbBlock from '../db-block'

export default ({ data, col, animation, duration }) => (
  <StaticQuery
    query={graphql`
      query {
        allContentfulTeamMember {
          edges {
            node {
              __typename
              id
              name
              position
            }
          }
        }
        allContentfulCaseStudy {
          edges {
            node {
              __typename
              id
              project
              client {
                name
                logo {
                  title
                  file {
                    url
                  }
                }
              }
              brief {
                childMarkdownRemark {
                  html
                }
              }
              snippet {
                childMarkdownRemark {
                  html
                }
              }
              summaryImage {
                fluid(maxWidth: 600) {
                  aspectRatio
                  src
                  srcSet
                  sizes
                }
                file {
                  url
                }
              }
            }
          }
        }
        allContentfulService {
          edges {
            node {
              __typename
              id
              title
              summaryImage {
                file {
                  url
                }
              }
              icon {
                file {
                  url
                  details {
                    image {
                      height
                      width
                    }
                  }
                }
              }
              snippet {
                childMarkdownRemark {
                  html
                }
              }
              content {
                childMarkdownRemark {
                  html
                }
              }
            }
          }
        }
        allContentfulTechnology {
          edges {
            node {
              __typename
              id
              name
              logo {
                title
                description
                file {
                  url
                  fileName
                  contentType
                }
              }
              snippet {
                childMarkdownRemark {
                  html
                }
              }
              description {
                childMarkdownRemark {
                  html
                }
              }
              specialist {
                id
                name
                position
              }
            }
          }
        }
        allContentfulJobs {
          edges {
            node {
              __typename
              id
              pageTitle
              snippet {
                childMarkdownRemark {
                  html
                }
              }
              icon {
                file {
                  url
                  details {
                    image {
                      height
                      width
                    }
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={qldata => {
      const blocks = qldata['allContentful' + data.contentType.replace(/\s/, '')].edges.map((blockNode, i) => {
        let block = blockNode.node
        block.__sectionTemplate = data.template
        return (
          <>
            {data.contentType === 'Technology' ? (
              <>
                {i % 5 === 0 ? <div className="desktop-row-break" /> : ''}

                <Col className="col-6 col-md-3 col-lg" key={block.id} data-aos={animation} data-aos-duration={duration}>
                  <DbBlock block={block} section={data} />
                </Col>
              </>
            ) : (
                <Col {...col} key={block.id} data-aos={animation} data-aos-duration={duration}>
                <DbBlock block={block} section={data} />
              </Col>
            )}
          </>
        )
      })

      return <>{blocks}</>
    }}
  />
)
