import React from "react"
import style from "./social.module.scss"

export default (data) => (
    <div className={`${style.socialicons} social-icons clearfix`}>
        {data.children}
        <ul>
            { ( data.instagram ) ? <li className={style.instagram}><a target="blank" rel="noopener noreferrer" href={'https://www.instagram.com/'+data.instagram+'/'}>Instagram</a></li> : '' }
            { ( data.twitter ) ? <li className={style.twitter}><a target="blank" rel="noopener noreferrer" href={'https://twitter.com/'+data.twitter}>Twitter</a></li> : '' }
            { ( data.facebook ) ? <li className={style.facebook}><a target="blank" rel="noopener noreferrer" href={'https://www.facebook.com/'+data.facebook}>Facebook</a></li> : '' }
            { ( data.linkedin ) ? <li className={style.linkedin}><a target="blank" rel="noopener noreferrer" href={'https://www.linkedin.com/company/'+data.linkedin}>LinkedIn</a></li> : '' }
        </ul>
    </div>
)
