import React from 'react'


export default ({ data }) => {
	return (
		<div>
          <img src={data.galleryImage.file.url} alt={data.galleryImage.title}  className="img-fluid" />
        </div>
	)
}
