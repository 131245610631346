import React, { Component } from 'react'
// import MobileNav from '../components/mobilenav'
import Nav from '../components/nav'
import FadeLink from '../components/pageTransition'
// import { Tween } from 'react-gsap'
import { TimelineMax } from 'gsap'
import Logo from '../images/db-website-logo.png'

export class header extends Component {
  constructor(props) {
    super(props)

    this.state = {
      scrolling: '',
      direction: '',
      Collapsed: false,
    }
    this.handleClick = this.handleClick.bind(this)
  }
  componentDidMount() {
    // this.handleScroll()
    // this.handleHover()
    // this.headerAnimation()
    if (document.body.classList.contains('fixed')) {
      document.body.classList.remove('fixed')
    }
  }

  headerAnimation() {
    return new TimelineMax()
      .delay(0.4)
      .fromTo(document.getElementsByClassName('header'), 1, { y: -100 }, { y: 0 })
      .staggerFromTo(
        [document.getElementsByClassName('logo'), document.getElementById('nav-wrap')],
        1,
        { opacity: 0 },
        { opacity: 1 },
        0.8
      )
  }
  handleHover() {
    const doc = document
    const anchors = doc.getElementById('desktop').getElementsByTagName('a')
    const highlight = doc.getElementById('arrow-indicator')
    const menu = doc.getElementById('nav-wrap').getBoundingClientRect()

    //add class to active
    var activeElement = doc.getElementsByClassName('active')
    if (activeElement.length) {
      doc.getElementsByClassName('active')[0].setAttribute('id', 'active')
      highlight.classList.add('show')
    }
    let target = doc.getElementById('active')

    if (target) {
      setTimeout(function() {
        let center = target.offsetWidth / 2
        highlight.style.cssText = '-webkit-mask-position-x:' + (menu.left + target.offsetLeft + center - 14) + 'px'
      }, 200)
    }

    for (let i = 0, len = anchors.length; i < len; i++) {
      anchors[i].addEventListener('mouseover', function(e) {
        window.clearTimeout(window.blockTimeout)
        let newtarget = e.target
        let newcenter = newtarget.offsetWidth / 2
        highlight.style.cssText =
          '-webkit-mask-position-x:' + (menu.left + newtarget.offsetLeft + newcenter - 14) + 'px'
      })
      anchors[i].addEventListener('mouseout', function(e) {
        if (target) {
          window.blockTimeout = setTimeout(function() {
            highlight.style.left = target.offsetLeft + 'px'
            highlight.style.cssText =
              '-webkit-mask-position-x:' + (menu.left + target.offsetLeft + target.offsetWidth / 2 - 14) + 'px'
          }, 200)
        } else {
          var storeNeg = '-' + highlight.style.width
          highlight.style.left = storeNeg
        }
      })
    }
  }

  handleClick() {
    if (this.state.Collapsed !== true) {
      document.body.classList.add('fixed')
    } else {
      document.body.classList.remove('fixed')
    }

    this.setState({
      Collapsed: !this.state.Collapsed,
    })
  }

  handleScroll() {
    let lastScrollTop = 1000

    window.addEventListener(
      'scroll',
      function() {
        let st = window.scrollY

        if (st < 5) {
          this.setState({
            scrolling: '',
            direction: '',
          })
        } else if (st > '116') {
          this.setState({
            scrolling: 'scrolling',
          })
        }
        if (st > lastScrollTop) {
          if (lastScrollTop > 5 || this.state.direction === 'scroll-up') {
            this.setState({
              direction: 'scroll-down',
            })
          }
        } else {
          if (lastScrollTop > 5 || this.state.direction === 'scroll-down') {
            this.setState({
              direction: 'scroll-up',
            })
          }
        }
        lastScrollTop = st
      }.bind(this)
    )
  }

  render() {
    return (
      <header className={`header ${this.state.scrolling + ' ' + this.state.direction}`}>
        <div className="container">
          <div className="border-top" id="arrow-indicator" />
          <div className="row">
            <div className="col">
              <FadeLink className="logo" to="/">
                <img src={Logo} alt="Double Brace Logo" />
              </FadeLink>
              <span id="navigation-holder">
                <ul className="contact-text">
                  <li className="telephone">
                    <a href="tel:01278444975">01278 444975</a>
                  </li>
                </ul>
                <div className="menu-buttons ">
                  <button
                    aria-label="Menu"
                    id="mob-nav"
                    className={this.state.Collapsed ? 'mob-button active' : 'mob-button'}
                    onClick={this.handleClick}
                  >
                    <div className="nav-icon">
                      <span />
                      <span />
                      <span />
                      <span />
                    </div>
                  </button>
                </div>
                <div id="mobile-menu" className={this.state.Collapsed ? 'active' : ''}>
                  <Nav location="mobile" show={this.state.Collapsed ? 'active' : ''} />
                  <ul className="contact-text">
                    <li className="telephone">
                      <a href="tel:01278444975">01278 444975</a>
                    </li>
                  </ul>
                </div>
              </span>
            </div>
            {/* <div className="col-lg-9 d-none d-md-none d-lg-block">
              <div id="nav-wrap">
                <Nav location="desktop" />
              </div>
            </div> */}
          </div>
        </div>
      </header>
    )
  }
}

export default header
