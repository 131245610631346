import React from 'react'
import FadeLink from '../../../components/pageTransition'
import { Card, CardBody, CardTitle, CardText } from 'reactstrap'
const slugify = require(`slugify`)

export default ({ data }) => {
  var path = '/articles/' + slugify(data.title, { lower: true })

  return (
    <Card className="article a-hover">
      <CardBody>
        <CardTitle tag="h2" className="h4">
          {data.title}
        </CardTitle>
        <p className="date"> {data.date} </p>
        <CardText tag="div" className="dyn-content">
          <div>By {data.author.name}</div>
          {data.snippet ? <div dangerouslySetInnerHTML={{ __html: data.snippet.childMarkdownRemark.html }} /> : ''}
          <FadeLink to={path} className="find-out-more">
            Find out more
          </FadeLink>
        </CardText>
      </CardBody>
    </Card>
  )
}
