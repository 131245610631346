import React, { Component } from 'react'
import { StaticQuery, graphql } from 'gatsby'
import FadeLink from '../components/pageTransition'

const handleClick = () => {
  if (document.body.classList.contains('fixed')) {
    document.body.classList.remove('fixed')
    document.getElementById('mobile-menu').classList.remove('active')
    document.getElementById('mob-nav').classList.remove('active')
  }
}

export class nav extends Component {
  render() {
    return (
      <StaticQuery
        query={graphql`
          query {
            contentfulMenu(handle: { eq: "main-navigation" }) {
              items {
                name
                path
              }
            }
          }
        `}
        render={data => {
          const navItems = (data.contentfulMenu.items || []).map((item, i) => {
            return (
              <li
                className={`menu-item ${this.props.show}`}
                key={`main-navigation-menu-${i}`}
              >
                <FadeLink onClick={handleClick} to={ item.path === '/' ? item.path : item.path + '/' } activeClassName={this.props.location === 'desktop' ? 'active' : 'mobileActive'}>
                  {item.name}
                </FadeLink>
              </li>
            )
          })

          return (
            <nav className="menu-main-container">
              <ul id={this.props.location}>{navItems}</ul>
            </nav>
          )
        }}
      />
    )
  }
}

export default nav
