import React from 'react'
import style from './db-button.module.scss'
import FadeLink from './pageTransition'

const DBButton = props => {
  return (
    <>
      {props.link ? (
        <FadeLink to={props.link} className={`${style.DBButton} ${props.className}`} onClick={props.onClick}>
          <div>
            <span>{props.label}</span>
          </div>
        </FadeLink>
      ) : (
        <button type={props.type} className={`${style.DBButton} ${props.className}`} onClick={props.onClick}>
          <div>
            <span>{props.label}</span>
          </div>
        </button>
      )}
    </>
  )
}

export default DBButton
