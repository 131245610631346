import React from 'react'
import { Container } from 'reactstrap'
import LandscapeBlocks from './sections/landscape'
import PortraitBlocks from './sections/portrait'
import SidebarBlocks from './sections/sidebar'
import SingleCarousel from './sections/single-carousel'
import Gallery from './sections/gallery'
import MediaStrip from './sections/media-strip'
import Inline from './sections/inline'

const sectionMappings = {
  LandscapeBlocks,
  PortraitBlocks,
  SidebarBlocks,
  SingleCarousel,
  Gallery,
  MediaStrip,
  Inline,
}

const DbSection = props => {
  let sectionComponentProps = {
    title: props.title,
    body: props.body,
    template: props.template,
    viewAllButton: props.viewAllButton,
    viewAllButtonText: props.viewAllButtonText,
    viewAllButtonLink: props.viewAllButtonLink
  }

  if (props.columnModel && props.columnModel.internal.content) {
    sectionComponentProps.colModel = JSON.parse(props.columnModel.internal.content)
  }

  const Section = sectionMappings[props.template] || Inline

  return (
    <section className={`${props.template}`}>
      <Container fluid={props.fullWidth}>
        <Section {...sectionComponentProps}>{props.blocks}</Section>
      </Container>
    </section>
  )
}

export default DbSection
