import React from 'react'

export default ({ data }) => {
  return (
    <div className="client">
      <div className="item">
        <img alt={data.logo.title} src={data.logo.file.url} />
      </div>
    </div>
  )
}
