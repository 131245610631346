import React from 'react'
import { Card, CardBody, CardTitle, CardText } from 'reactstrap'
import styled from 'styled-components'
import { Tween } from 'react-gsap'
import DBButton from '../../db-button'

const slugify = require(`slugify`)

export default ({ data, CaseStudy }) => {
  var path = '/services/' + slugify(data.title, { lower: true })
  return (
    <Tween css={{ top: '-20%', opacity: 0 }} delay={2.5} duration={1}>
    <Card className="a-hover">
      <CardBody>
        <div className={`row flex-column flex-md-row ${CaseStudy ? 'no-gutters' : ''}`}>
          <div className={CaseStudy ? 'col-12 col-md-4 image-holder' : 'col-12 col-md-4 image-holder order-1 order-md-2'}>
            {data.icon ? (
              CaseStudy ? (
                <SvgCaseStudy background={data.icon.file.url} width={data.icon.file.details.image.width} height={data.icon.file.details.image.height} className="landscape-card-svg-case-study" />
              ) : (
                <Svg background={data.icon.file.url} width={data.icon.file.details.image.width} height={data.icon.file.details.image.height} className="landscape-card-svg" />
              )
            ) : (
              ''
            )}
          </div>
          <div className={CaseStudy ? 'col-12 col-md-8 align-self-center' : 'col-12 col-md-8 align-self-center order-2 order-md-1'}>
            <CardTitle tag="p" className={ CaseStudy ? 'h4 no-gradient' : 'h4'}>
              {data.title}
            </CardTitle>
            {!CaseStudy && (
              <CardText tag="div" className="dyn-content">
                {data.snippet ? (
                  <div
                    dangerouslySetInnerHTML={{ __html: data.snippet.childMarkdownRemark.html }}
                    className="d-none d-md-block"
                  />
                ) : (
                  ''
                )}
                <DBButton label="Find out More" link={path} />
              </CardText>
            )}
          </div>
        </div>
      </CardBody>
    </Card>
    </Tween>
  )
}

const Svg = styled.div`
  -webkit-mask: url(${props => props.background}) no-repeat;
  mask: url(${props => props.background}) no-repeat;
`

const SvgCaseStudy = styled.div`
  -webkit-mask: url(${props => props.background}) no-repeat;
  mask: url(${props => props.background}) no-repeat;
`
