import React from 'react'

// const slugify = require(`slugify`)

export default ({ data }) => {
  // var path = '/technologies/' + slugify(data.name, { lower: true })
  return (
    <div className="technology">
      {data.logo ? <img className="img-fluid" src={data.logo.file.url} alt={data.name} /> : <p>{data.name}</p>}
       {/* {data.snippet ? <div dangerouslySetInnerHTML={{ __html: data.snippet.childMarkdownRemark.html }} /> : ''} */}
    </div>
  )
}
