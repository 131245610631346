import React from 'react'
import styles from './stat.module.scss'

export default ({ data }) => {
  return (
      <div className={styles.stat}>
        <p>{data.value}</p>
        <p>{data.key}</p>
      </div>
  )
}
