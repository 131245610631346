import React from 'react'
import DbBlocks from '../db-blocks'

export default props => {
  const { colModel, children, title, body, ...sectionProps } = props

  return (
    <DbBlocks col={{}} section={sectionProps}>
      {children}
    </DbBlocks>
  )
}
