import React from 'react'
import styles from './insta.module.scss'

const text_truncate = (str, length, ending) => {
  if (length == null) {
    length = 80
  }
  if (ending == null) {
    ending = '...'
  }
  if (str.length > length) {
    return str.substring(0, length - ending.length) + ending
  } else {
    return str
  }
}

class instaFeed extends React.Component {
  constructor(props) {
    super(props)

    let numberPosts = 12;
    if (props.rows) {
      numberPosts = props.rows * 6;
    }
    
    this.state = {
      error: null,
      isLoaded: false,
      items: [],
      fetchItems: numberPosts
    }
  }
  componentDidMount() {
    fetch(
      'https://graph.instagram.com/me/media?fields=id,caption,media_type,media_url,thumbnail_url,permalink&access_token=IGQVJVcGJ3YWxsVnd5YXpTQ3AwclEzR1ZAVbjUwNGhicVJ3amxtcXZAyandhTHFlX3pnWnFkTXBUR3NWa3lDU2Frbnptck1EUFVXZAkZArRElwaFpBTFB1QWVNdHFBeEhVY3BfYmhuR3ozMUVMNWZAiR1VyeQZDZD' +
        '&limit=' + this.state.fetchItems
    )
      .then(res => res.json())
      .then(
        result => {
          this.setState({
            isLoaded: true,
            items: result.data,
          })
        },
        error => {
          this.setState({
            isLoaded: true,
            error,
          })
        }
      )
  }
  render() {
    const { error, isLoaded, items } = this.state
    if (error) {
        return console.error(error.message);
    } else if (!isLoaded) {
      return <div className="text-center">Loading...</div>
    } else {
      return (
        <div className="row no-gutters">
          {items.map(item => (
            <div className="col-4 col-md-3 col-lg-2" key={item.id}>
              <a
                href={item.permalink}
                className={styles.instaBlock}
                target="_block"
                rel="nofollow noopener"
              >
                <img className="img-fluid" src={item.media_type === 'VIDEO' ? item.thumbnail_url : item.media_url} alt={item.caption} />
                <div className={styles.caption}>
                  <span>{text_truncate(item.caption)} </span>
                </div>
              </a>
            </div>
          ))}
        </div>
      )
    }
  }
}

export default instaFeed
