import React from 'react'
import DbBlock from '../db-block'
import { Row, Col } from 'reactstrap'
import Swiper from 'react-id-swiper'
import style from './single-carousel.module.scss'
import DBButton from '../../../components/db-button'

const SingleCarousel = props => {
  var col = props.colModel || { xs: 12 }

  if (typeof window !== `undefined`) {
    var w = window.innerWidth
  }
  var effect = ''

  if (w >= 991) {
    effect = 'slide'
  }

  const params = {
    grabCursor: true,
    centeredSlides: true,
    slidesPerView: 1,
    speed: 700,
    preloadImages: false,
    lazy: true,
    effect: effect,
    spaceBetween: 200,
    pagination: {
      el: '.swiper-pagination',
      clickable: true,
    },
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
    // Responsive breakpoints
    breakpoints: {
      // when window width is <= 640px
      991: {
        spaceBetween: 20,
      },
    },
  }

  const slides = props.children.map((block, i) => {
    block.__sectionTemplate = props.template
    return (
      <div className={style.item} key={i}>
        <Row className="h-100">
          <Col {...col}>
            <DbBlock block={block} section={props} />
          </Col>
        </Row>
      </div>
    )
  })

  return <Swiper {...params}>{slides}</Swiper>
}

export default props => {
  if (typeof window !== `undefined`) {
    var width = window.innerWidth
  }
  return (
    <div id="single-carousel">
      {width >= 991 ? (
        <span id="clientShapesTop" data-aos="fade-left" data-aos-duration="450">
          <div className="shapes circle--2 bounce" />
          <div className="shapes circle--6 bounce" />
          <div className="shapes rectangle--1 bounce" />
          <div className="shapes circle--3 bounce" />
          <div className="shapes triangle--2 bounce" />
          <div className="shapes rectangle--2 bounce" />
          <div className="shapes triangle--1 bounce" />
        </span>
      ) : (
        ''
      )}

      <div className="single-carousel-wrap" data-aos="fade-up" data-aos-duration="450">
        <h2 className="section-title">{props.title}</h2>
        <SingleCarousel data={props} className={style.SingleCarousel}>
          {props.children}
        </SingleCarousel>
        { props.viewAllButton ? (
          <div className="col text-center" data-aos="fade-in" data-aos-duration="450">
            <DBButton className="carousel-button" label={props.viewAllButtonText} link={props.viewAllButtonLink.path}/>
          </div>
        ) : null }
      </div>

      {width >= 991 ? (
        <div className="clientShapesBottom">
          <div className="shapes circle--2 bounce" />
          <div className="shapes circle--6 bounce" />
          <div className="shapes rectangle--1 bounce" />
          <div className="shapes circle--3 bounce" />
          <div className="shapes triangle--2 bounce" />
          <div className="shapes rectangle--2 bounce" />
          <div className="shapes triangle--1 bounce" />
        </div>
      ) : (
        ''
      )}
    </div>
  )
}
