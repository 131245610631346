import React from 'react'
// import BackgroundImage from 'gatsby-background-image'
import FadeLink from '../../../components/pageTransition'
import Img from "gatsby-image"
import DBButton from '../../../components/db-button'
const slugify = require(`slugify`)

export default ({ data }) => {
  var path = '/case-studies/' + slugify(data.project, { lower: true }) + '/'
  return (
    <div className="case-study">
      <FadeLink to={path}>
        <div className="row">
          <div className="col-12 col-lg case-study-image order-lg-2">
            {data.summaryImage ? (
              /* <BackgroundImage tag="div" className="background-col" fluid={data.summaryImage.fluid} /> */
              <Img fluid={data.summaryImage.fluid} />
            ) : (
              ''
            )}
          </div>
          <div className="col-12 col-lg">
            <div className="content">
              {data.client.logo ? (
                <img
                  className="img-fluid client-logo"
                  src={data.client.logo.file.url}
                  alt={data.client.name + 'Logo'}
                />
              ) : (
                ''
              )}
              <p className="h4">{data.project}</p>
              {data.__sectionTemplate !== 'PortraitBlocks' && data.snippet ? (
                <div className="snippet" dangerouslySetInnerHTML={{ __html: data.snippet.childMarkdownRemark.html }} />
              ) : (
                ''
              )}
              {data.stats ? (
                <div className="stats">
                  {data.stats.slice(0, 2).map(stat => {
                    return (
                      <div className="stat" key={stat.id}>
                        <span className="value">{stat.value}</span>
                        <span className="text">{stat.key} </span>
                      </div>
                    )
                  })}
                </div>
              ) : (
                ''
              )}
              <DBButton label='View Project' className="find-out-more"/>
            </div>
          </div>
        </div>
      </FadeLink>
    </div>
  )
}
