import React from 'react'
import DbBlocks from '../db-blocks'
import { Row } from 'reactstrap'
import DBButton from '../../../components/db-button'

export default props => {
  const { colModel = { xs: 6 }, children, title, viewAllButton, viewAllButtonText, viewAllButtonLink, body, ...sectionProps } = props

  return (
    <>
      <div className="row">
        <div className="col">
          <h2 data-aos="fade-in" data-aos-duration="450" className="section-title">
            {title}
          </h2>
        </div>
        { viewAllButton ? (
          <div className="col d-none d-sm-block text-right" data-aos="fade-in" data-aos-duration="450">
            <DBButton label={viewAllButtonText} link={viewAllButtonLink.path}/>
          </div>
        ) : null }
      </div>

      {body && body.childMarkdownRemark.html ? (
        <div
          dangerouslySetInnerHTML={{
            __html: body.childMarkdownRemark.html,
          }}
        />
      ) : null}
      <Row>
        <DbBlocks col={colModel} section={sectionProps} animation="fade-up" duration="1000">
          {children}
        </DbBlocks>
      </Row>
      { viewAllButton ? (
      <div className="d-block d-sm-none text-center">
        <DBButton label={viewAllButtonText} link={viewAllButtonLink.path} />
      </div>
      ) : null }
    </>
  )
}
