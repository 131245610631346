import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import FadeLink from '../components/pageTransition'
import { Row, Col } from 'reactstrap'
import SocialIcons from '../components/social/social'
// import Cursor from '../components/cursor'

const ScrollTop = () => {
  var scrollStep = -window.scrollY / (700 / 15),
    scrollInterval = setInterval(function() {
      if (window.scrollY !== 0) {
        window.scrollBy(0, scrollStep)
      } else clearInterval(scrollInterval)
    }, 15)
}

const Credits = props => (
  <div className="footer-bottom">
    <div className="container">
      <Row className="align-items-center">
        <Col xs="12" md="3">
          <p id="footer-copyright">
            &copy; {new Date().getFullYear()} {props.data.site.siteMetadata.title}
          </p>
        </Col>
        <Col xs="12" md="6">
          <p id="footer-information">
            Registered in {props.data.site.siteMetadata.company.placeOfRegistration} company no.{' '}
            {props.data.site.siteMetadata.company.number} vat number {props.data.site.siteMetadata.company.vatNumber}<br />
            Business Address: {props.data.site.siteMetadata.address}
          </p>
        </Col>
        <Col xs="12" md="3" className={'text-center'}>
          <button id="footer-go-to-top" onClick={ScrollTop} onKeyPress={ScrollTop}>
            <span className="arrow" />
            <span className="text">TO THE TOP</span>
          </button>
        </Col>
      </Row>
    </div>
  </div>
)

export default (props) => (
  <StaticQuery
    query={graphql`
      query {
        site {
          siteMetadata {
            title
            company {
              number
              vatNumber
              placeOfRegistration
            }
            telephone
            email
            address
            social {
              facebook
              instagram
              linkedin
              twitter
            }
          }
        }
      }
    `}
    render={data => (
      <>              
      <footer>

        <div className="footer-top">
          <div className="container">
            <Row className="align-items-center">
              <Col xs="12" md="2" className="text-center">
                <FadeLink to="/">
                   <svg xmlns="http://www.w3.org/2000/svg" width="51" height="66" viewBox="0 0 51 66">
                      <defs>
                        <linearGradient id="a" x1="320.981%" x2="-81.082%" y1="54.068%" y2="54.068%">
                          <stop stopColor="#F034A8" offset="0%"/>
                          <stop stopColor="#07C5D1" offset="100%"/>
                        </linearGradient>
                      </defs>
                      <path fill="url(#a)" fillRule="evenodd" d="M915.424893,93.036003 C915.633315,91.7248347 915.748937,90.3194191 915.748937,88.8054306 C915.748937,67 892.51474,67 892.51474,67 L875,67 L875,88.8054306 L875,110.610861 L884.5323,110.610861 L884.5323,111.197079 L884.5323,133.00251 L902.047421,133.00251 C902.047421,133.00251 925.281617,133.00251 925.281617,111.197079 C925.281237,101.400262 920.590963,96.0074317 915.424893,93.036003 Z M884.650964,75.4407875 L893.229768,75.4407875 C893.229768,75.4407875 905.561703,75.9681954 905.561703,88.8054306 C905.561703,101.642666 893.229768,102.170074 893.229768,102.170074 L884.650964,102.170074 L884.650964,88.8054306 L884.650964,75.4407875 Z" transform="translate(-875 -67)"/>
                    </svg>
                </FadeLink>
              </Col>
              <Col xs="12" md="5" className="order-md-first">
                <SocialIcons {...data.site.siteMetadata.social}>
                  <p>
                    <span>Keep it fresh.</span> Follow us:
                  </p>
                </SocialIcons>
              </Col>
              <Col xs="12" md="5">
                <div className="footer-contact">
                  <a className="telephone" href={'tel:' + data.site.siteMetadata.telephone.replace(/\s/g, '')}>
                    <span>T: </span>
                    {data.site.siteMetadata.telephone}
                  </a>
                  <a className="email" href={'mailto:' + data.site.siteMetadata.email}>
                    <span>E: </span> {data.site.siteMetadata.email}
                  </a>
                </div>
              </Col>
            </Row>
          </div>
        </div>
        <Credits data={data} />
        {/* <Cursor /> */}
        </footer>
        </>
    )}
  />
)
